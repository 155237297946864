import './css/Contatti.css';
import logo from '../logo/nero.png';
import insta from '../logo/ig.svg';
import whats from '../logo/whatsapp.svg';
import mail from '../logo/mail.svg';
import LinkWithImage from '../components/LinkWithImage';
import text from '../texts/texts.json';
import { useState, useEffect } from 'react';
import ContactCarousel from '../components/ContactCarousel';
import { Link } from 'react-router-dom';

import img1 from '../images/Studio/Studio4_scelta.png';
import img3 from '../images/Studio/Studio5_scelta.png';
import img4 from '../images/Studio/Studio6.jpeg';
import img8 from '../images/Studio/Indicazioni_alto.jpeg';
import img9 from '../images/Studio/Indicazionisx.jpeg';
import img10 from '../images/Studio/Indicazioni2.jpeg';
import img11 from '../images/Studio/Inidcazionilatoopposto.jpeg';

const link_whats = 'https://wa.me/+3933403595298';
const link_insta = 'https://www.instagram.com/chieri_wakabayashi/';
const link_mail = "https://mail.google.com/mail/?view=cm&fs=1&to=chieriwak@gmail.com&su=Oggetto&body=Corpo%20del%20messaggio";


function Contatti() {
    const images = [img1, img3, img4, img8, img9, img10, img11];
    return (

        <>
            <div className='content_contatti' >
                <div className='content_contatti_sub'>
                    <div className="contact-container">
                        <div className="contact-info">
                            <h3>{text.contatti_testo.title}</h3>
                            <p>{text.contatti_testo.content}</p>
                            <p>
                                Puoi trovarmi presso il mio studio in:
                            </p>
                            <Link to={`https://maps.google.com/?q=${text.contatti_diretti.indirizzo.via} ${text.contatti_diretti.indirizzo.cap} ${text.contatti_diretti.indirizzo.città}`} className="footer-link">
                                📍 {text.contatti_diretti.indirizzo.via} {text.contatti_diretti.indirizzo.cap} {text.contatti_diretti.indirizzo.città}
                            </Link>

                            <strong>Per informazioni e prenotazioni:</strong>
                            <p> Contattami al <Link>+393403595298</Link>.</p>

                        </div>
                        <div className="map">
                            <ContactCarousel images={images} />
                        </div>

                        <div className="contact-form">
                            <div className='flex_form'>
                                <div className="social-icons">
                                    <LinkWithImage imageSrc={whats} altText="Whatsapp" linkUrl={link_whats} />
                                    <LinkWithImage imageSrc={insta} altText="Instagram" linkUrl={link_insta} />
                                    <LinkWithImage imageSrc={mail} altText="Gmail" linkUrl={link_mail} />
                                </div>
                                <div className='footer'>
                                    <Link to={`https://maps.google.com/?q=${text.contatti_diretti.indirizzo.via} ${text.contatti_diretti.indirizzo.cap} ${text.contatti_diretti.indirizzo.città}`} className="footer-link">
                                        📍 {text.contatti_diretti.indirizzo.via} {text.contatti_diretti.indirizzo.cap} {text.contatti_diretti.indirizzo.città}
                                    </Link>
                                    <p className="footer-link" >📞  {text.contatti_diretti.telefono}</p>
                                </div>
                                <div className='footer'>
                                    <p className="footer-link">✉️ {text.contatti_diretti.email}</p>
                                    <p className="footer-link">p.iva: {text.contatti_diretti.partita_iva}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Contatti;
