import Carousel from 'react-bootstrap/Carousel';
import text from '../texts/texts.json'
import chieri2 from '../images/chieri/chieri2.jpg';
import chieri3 from '../images/chieri/chieri_not_formatted1.jpg';
import chieri4 from '../images/chieri/chieri4.jpg';
import chieri5 from '../images/DSC_2624.webp';
import AboutMe from '../pages/Aboutme';
import '../pages/css/Abcarousel.css';
import { useState, useEffect } from 'react';


const testi1 = [
  "",
  "",
  text.chisono.slide1,
];
const testi2 = [
  "Perché lo Shiatsu?",
  "",
  "",
  text.chisono.slide2,
];
const testi3 = [
  "La mia formazione",
  "",
  text.chisono.slide3,
  "",
];

const testimobile2 = [
  "Perché lo Shiatsu?",
  "",
  text.chisono.slide2,
  "" 
];


const img = [
  chieri3,
];
const img2 = [
  null,
  chieri2,
];

const img3 = [
  chieri5,
];

const img2mobile = [
  chieri2,
];

function AboutMeCarousel() {

  return (
    <>
        <div>
          <Carousel interval={null}>
            <Carousel.Item>
              <AboutMe text={testi1} images={img}></AboutMe>
            </Carousel.Item>
            <Carousel.Item>
              <AboutMe text={testi2} images={img2}></AboutMe>
            </Carousel.Item>
            <Carousel.Item>
              <AboutMe text={testi3} images={img3}></AboutMe>
            </Carousel.Item>
          </Carousel>
        </div>
     
    </>
  );
}

export default AboutMeCarousel;