
import './mobile.css';
import chieri2 from '../../images/chieri/chieri2.jpg';
import chieri3 from '../../images/chieri/chieri_not_formatted1.jpg';
import chieri4 from '../../images/DSC_2624.webp';
import text from '../../texts/texts.json';
import Navbar_mobile from '../../components/Navbar_mobile';
import Box_content from './Component_mobile/Box_content';


const testi1 = [
    "",
    "",
    text.chisono.slide1,
];
const testi2 = [
    "Perchè lo Shiatsu?",
    "",
    text.chisono.slide2,
];
const testi3 = [
    "La mia formazione",
    "",
    text.chisono.slide3,
    "",
];

const img = [
    chieri3,
];
const img2 = [
    chieri2,
];

const img3 = [
    chieri4,
];


function About_mobile() {
    return (
        <div className='mobile'>
            <div className='content_ab'>
                <Navbar_mobile />
                <div className='body_ab'>
                    <Box_content text={testi1} images={img} />
                    <Box_content text={testi2} images={img2} />
                    <Box_content text={testi3} images={img3} />
                </div>

            </div>
        </div>
    );
}
export default About_mobile;